/* NOTIFICATIONS */
.notification {
    font-family: 'Exo 2', sans-serif;
    opacity: 0.95;
}

.notification-container {
    overflow: unset;
}

.notification-success {
    background-color: #f5f5f5;
    color: black;
}
