/* FONTS */
@font-face {
    font-family: 'Exo 2';
    src: url('../fonts/Exo2-Regular.ttf');
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'Exo 2';
    src: url('../fonts/Exo2-Bold.ttf');
    font-style: normal;
    font-weight: bold;
}

@font-face {
    font-family: 'Exo 2';
    src: url('../fonts/Exo2-SemiBold.ttf');
    font-style: normal;
    font-weight: 600;
}

/* ANIMATIONS */
@-webkit-keyframes color-change-white-to-lawngreen {
    0% {
        color: white;
    }
    50% {
        color: lawngreen
    }
    100% {
        color: white;
    }
}

@-moz-keyframes color-change-white-to-lawngreen {
    0% {
        color: white;
    }
    50% {
        color: lawngreen
    }
    100% {
        color: white;
    }
}

@-ms-keyframes color-change-white-to-lawngreen {
    0% {
        color: white;
    }
    50% {
        color: lawngreen
    }
    100% {
        color: white;
    }
}

@-o-keyframes color-change-white-to-lawngreen {
    0% {
        color: white;
    }
    50% {
        color: lawngreen
    }
    100% {
        color: white;
    }
}

@keyframes color-change-white-to-lawngreen {
    0% {
        color: white;
    }
    50% {
        color: lawngreen
    }
    100% {
        color: white;
    }
}

@-webkit-keyframes background-warning {
    0% {
        background-color: rgba(0, 0, 0, 0.5)
    }
    50% {
        background-color: rgba(97, 3, 3, 0.5)
    }
    100% {
        background-color: rgba(0, 0, 0, 0.5)
    }
}

@-moz-keyframes background-warning {
    0% {
        background-color: rgba(0, 0, 0, 0.5)
    }
    50% {
        background-color: rgba(97, 3, 3, 0.5)
    }
    100% {
        background-color: rgba(0, 0, 0, 0.5)
    }
}

@-ms-keyframes background-warning {
    0% {
        background-color: rgba(0, 0, 0, 0.5)
    }
    50% {
        background-color: rgba(97, 3, 3, 0.5)
    }
    100% {
        background-color: rgba(0, 0, 0, 0.5)
    }
}

@-o-keyframes background-warning {
    0% {
        background-color: rgba(0, 0, 0, 0.5)
    }
    50% {
        background-color: rgba(97, 3, 3, 0.5)
    }
    100% {
        background-color: rgba(0, 0, 0, 0.5)
    }
}

@keyframes background-warning {
    0% {
        background-color: rgba(0, 0, 0, 0.5)
    }
    50% {
        background-color: rgba(97, 3, 3, 0.5)
    }
    100% {
        background-color: rgba(0, 0, 0, 0.5)
    }
}

@-webkit-keyframes show-element {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes show-element {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-ms-keyframes show-element {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes show-element {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


@keyframes show-element {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes breath {
    0% {
        opacity: 0.7;
        transform: scale(1)
    }
    50% {
        opacity: 1;
        transform: scale(1.2)
    }
    100% {
        opacity: 0.7;
        transform: scale(1)
    }
}

@-moz-keyframes breath {
    0% {
        opacity: 0.7;
        transform: scale(1)
    }
    50% {
        opacity: 1;
        transform: scale(1.2)
    }
    100% {
        opacity: 0.7;
        transform: scale(1)
    }
}

@-ms-keyframes breath {
    0% {
        opacity: 0.7;
        transform: scale(1)
    }
    50% {
        opacity: 1;
        transform: scale(1.2)
    }
    100% {
        opacity: 0.7;
        transform: scale(1)
    }
}

@-o-keyframes breath {
    0% {
        opacity: 0.7;
        transform: scale(1)
    }
    50% {
        opacity: 1;
        transform: scale(1.2)
    }
    100% {
        opacity: 0.7;
        transform: scale(1)
    }
}

@keyframes breath {
    0% {
        opacity: 0.7;
        transform: scale(1)
    }
    50% {
        opacity: 1;
        transform: scale(1.2)
    }
    100% {
        opacity: 0.7;
        transform: scale(1)
    }
}

@-webkit-keyframes reappear {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    60% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

@-moz-keyframes reappear {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    60% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

@-ms-keyframes reappear {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    60% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

@-o-keyframes reappear {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    60% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}


@keyframes reappear {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    60% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}
